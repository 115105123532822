import React from 'react';
import { graphql, Link, PageProps } from 'gatsby';
import { FluidObject } from 'gatsby-image';

import Heading from '../components/heading';
import Layout from '../components/layout';
import SEO from '../components/seo';

type Props = Queries.ProductPagesQuery;

const ProductsIndex = ({ data }: PageProps<Props>) => {
  const { nodes: products } = data.allMarkdownRemark;
  return (
    <Layout>
      <Heading
        title="Products"
        image={(data.background?.childImageSharp?.fluid as FluidObject) ?? undefined}
      />
      <div className="px-4 pb-20 pt-16 sm:px-6 lg:px-8 lg:pb-28 lg:pt-24">
        <div className="relative mx-auto max-w-7xl">
          <div className="mx-auto mt-6 grid max-w-lg gap-5 border-t-2 border-gray-100 pt-10 lg:max-w-none lg:grid-cols-3">
            {products.map((node) => {
              const title = node.frontmatter.title ?? node.fields.slug;

              return (
                <article
                  key={node.id}
                  className="flex flex-col overflow-hidden rounded-lg shadow-lg"
                >
                  <div className="flex-shrink-0">
                    <img
                      className="h-48 w-full object-cover"
                      src="https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80"
                      alt={title}
                    />
                  </div>
                  <div className="flex flex-1 flex-col justify-between bg-white p-6">
                    <div className="flex-1">
                      <Link className="block" to={node.fields?.slug ?? ''}>
                        <header>
                          <h3 className="mt-2 text-xl font-semibold leading-7 text-gray-900">
                            {title}
                          </h3>
                        </header>
                        <section>
                          {/* eslint-disable react/no-danger */}
                          <p
                            className="mt-3 text-base leading-6 text-gray-500"
                            dangerouslySetInnerHTML={{
                              __html: node.frontmatter.description ?? node.excerpt ?? '',
                            }}
                          />
                          {/* eslint-enable react/no-danger */}
                        </section>
                      </Link>
                    </div>
                  </div>
                </article>
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const Head = () => <SEO title="Products" />;

export default ProductsIndex;

export const pageQuery = graphql`
  query ProductPages {
    allMarkdownRemark(filter: { fields: { slug: { glob: "/projects/*" } } }) {
      nodes {
        excerpt(pruneLength: 250)
        id
        fields {
          slug
        }
        frontmatter {
          title
          description
        }
      }
    }
    background: file(relativePath: { eq: "backgrounds/IMG_3658.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
