import clsx from 'clsx';
import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import { FluidObject } from 'gatsby-image';

interface Props {
  title: string;
  image: FluidObject | undefined;
  className?: string;
  as?: any;
}

const Heading = ({ title, image, className, as, children }: React.PropsWithChildren<Props>) => (
  <BackgroundImage Tag={as} fluid={image} className="shadow-inner">
    <div
      className={clsx(
        'text-shadow-md mx-auto mb-6 mt-12 max-w-screen-xl px-4 text-gray-300 sm:px-6 lg:px-8',
        className,
      )}
    >
      <h2 className="text-shadow-lg text-3xl font-extrabold leading-9 tracking-tight text-gray-200 sm:text-4xl">
        {title}
      </h2>
      {children}
    </div>
  </BackgroundImage>
);

export default Heading;
